interface WasteType {
  id: string;
  name: string;
  description: string;
  color: string;
  iconUrl: string | null;
  isPickupPointEditable: boolean;
}

export function emptyWasteType(): WasteType {
  return {
    id: '',
    name: '',
    description: '',
    color: '',
    iconUrl: null,
    isPickupPointEditable: false,
  };
}

export default WasteType;

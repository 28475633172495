


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class NotificationTypeChip extends Vue {
  @Prop() private type!: string;
}

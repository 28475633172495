














































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PickupDay from '@/models/pickupDay';
import ScheduleApi from '@/api/scheduleApi';
import WasteType from '@/models/wasteTypes';
import WasteTypesApi from '@/api/wasteTypesApi';
import AddPickupDay from '@/components/AddPickupDay.vue';
import Zone from '@/models/zone';

@Component({
  components: {
    AddPickupDay,
  },
})
export default class Calendar extends Vue {
  private weekdays = [1, 2, 3, 4, 5, 6, 0];

  private schedule: PickupDay[] = [];

  private selectedZoneId: string | null | undefined = null;

  private selectedWasteTypeId: string | null | undefined = null;

  private focus = '';

  private today = '';

  private start: Start | null = null;

  private end: End | null = null;

  private dialog = false;

  private selectedEvent: Event = {
    id: '',
    name: '',
    start: new Date(),
    end: new Date(),
    color: '',
  };

  private selectedElement = null;

  private selectedOpen = false;

  private startDate = new Date();

  private endDate = new Date();

  @Watch('selectedZoneId')
  @Watch('selectedWasteTypeId')
  async getSchedule() {
    this.schedule = await ScheduleApi.getFromDateToDate(
      this.startDate,
      this.endDate,
      this.selectedZoneId,
      this.selectedWasteTypeId,
    );
  }

  get zones() {
    return this.$store.state.zones;
  }

  get wasteTypes() {
    return this.$store.state.wasteTypes;
  }

  mounted() {
    this.calendarInstance.checkChange();
  }

  get scheduleEvents(): Event[] {
    const events: Event[] = [];

    Object.values(this.schedule).forEach(
      (pickupDay) => {
        const f = new Date(pickupDay.fromDatetime);
        // const startDate = `${f.getFullYear()}-${f.getMonth() + 1}-${f.getDate()}`;
        // const startTime = f.toLocaleTimeString('en-UK');
        const t = new Date(pickupDay.toDatetime);
        // const endDate = `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
        // const endTime = t.toLocaleTimeString('en-UK');
        const event = {
          name: `${pickupDay.zone.name} - ${pickupDay.wasteType.name}`,
          // start: `${startDate} ${startTime}`,
          // end: `${endDate} ${endTime}`,
          start: f,
          end: t,
          id: pickupDay.id,
          color: `#${pickupDay.wasteType.color}`,
        };
        events.push(event);
      },
    );

    return events;
  }

  setToday() {
    this.focus = this.today;
  }

  prev() {
    this.calendarInstance.prev();
  }

  next() {
    this.calendarInstance.next();
  }

  updateRange({ start, end }: {start: Start; end: End}) {
    this.startDate = new Date(`${start.date}T00:00:00`);
    this.endDate = new Date(`${end.date}T23:59:59`);
    this.getSchedule();
    this.start = start;
    this.end = end;
  }

  private get calendarInstance(): Vue & {
    prev: () => void;
    next: () => void;
    checkChange: () => void;
    getFormatter: (format: any) => any;
    } {
    return this.$refs.calendar as Vue & {
      prev: () => void;
      next: () => void;
      checkChange: () => void;
      getFormatter: (format: any) => any;
    };
  }

  closeAddDayModal() {
    this.getSchedule();
    this.dialog = false;
  }

  showEvent({ nativeEvent, event }: any) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => {
        this.selectedOpen = true;
      }, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  getPickupDayEvent(eventId: string) {
    return this.schedule.find((p) => p.id === eventId)!;
  }

  getEventFancyName(eventId: string) {
    const event = this.getPickupDayEvent(eventId);
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };
    const fromTime = new Date(event.fromDatetime).toLocaleTimeString([], options);
    const toTime = new Date(event.toDatetime).toLocaleTimeString([], options);
    return `${fromTime} - ${toTime} ${event.zone.name}`;
  }

  get pickupDayEvent() {
    return this.schedule.find((p) => p.id === this.selectedEvent.id)!;
  }

  getEventColor(event: Event) {
    const pickupDay = this.schedule.find((p) => p.id === event.id)!;
    return `#${pickupDay.wasteType.color}`;
  }

  async deletePickupDay(pickupDayId: string) {
    await ScheduleApi.deletePickupDay(pickupDayId);
    this.selectedOpen = false;
    this.getSchedule();
  }
}

export type Start = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
export type End = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
export type Event = {
  id: string;
  name: string;
  start: Date;
  end: Date;
  color: string;
}

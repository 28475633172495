













import { Component, Prop, Vue } from 'vue-property-decorator';
import WasteType, { emptyWasteType } from '@/models/wasteTypes';

@Component({
  components: {
  },
})
export default class WasteTypeChip extends Vue {
  @Prop() private wasteType!: WasteType;

  get color() {
    return `#${this.wasteType.color}`;
  }
}

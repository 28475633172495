import { HTTP } from '@/lib/http/client';
import WasteCollector from '@/models/wasteCollector';

export default class WasteCollectorsApi {
  static async getMe(): Promise<WasteCollector> {
    const res = await HTTP.get('profile');
    return res.data;
  }

  static async changePassword(currentPassword: string, newPassword: string) {
    const res = await HTTP.put(
      'profile/password',
      {
        currentPassword,
        newPassword,
      },
    );
  }
}

import Store, { emptyStore } from './store';
import WasteType, { emptyWasteType } from './wasteTypes';

interface Pickup {
  id: string;
  store: Store;
  wasteType: WasteType;
  pickupPointX: number;
  pickupPointY: number;
  status: string;
  photoStoreUrl: string;
  photoWasteCollectorBeforeUrl: string;
  photoWasteCollectorAfterUrl: string;
  completedAt: Date;
  createdAt: Date;
}

export function emptyPickup(): Pickup {
  return {
    id: '',
    store: emptyStore(),
    wasteType: emptyWasteType(),
    pickupPointX: 0.0,
    pickupPointY: 0.0,
    status: 'P',
    photoStoreUrl: '',
    photoWasteCollectorBeforeUrl: '',
    photoWasteCollectorAfterUrl: '',
    completedAt: new Date(),
    createdAt: new Date(),
  };
}

export default Pickup;

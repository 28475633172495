import { HTTP } from '@/lib/http/client';
import Store from '@/models/store';
import { PaginatedResponse, parsePaginatedResponse } from '@/models/paginatedResponse';

export default class StoresApi {
  static async getAll(
    q: string,
    status: string,
    page: number,
    perPage: number,
  ): Promise<PaginatedResponse<Store[]>> {
    let path = `stores?page=${page}&per_page=${perPage}`;
    if (q !== '') {
      path = `${path}&q=${q}`;
    }
    if (status!) {
      path = `${path}&status=${status}`;
    }
    const res = await HTTP.get(path);
    return parsePaginatedResponse(res);
  }

  static async getById(storeId: string): Promise<Store> {
    const res = await HTTP.get(`stores/${storeId}`);
    return res.data;
  }

  static async updateStoreById(storeId: string, store: Store): Promise<Store> {
    const payload = Object();
    if (store.email!) payload.email = store.email;
    if (store.name!) payload.name = store.name;
    if (store.phone!) payload.phone = store.phone;
    if (store.address!) payload.address = store.address;
    if (store.city!) payload.city = store.city;
    if (store.nif!) payload.nif = store.nif;
    if (store.pickupPointX!) payload.pickupPointX = store.pickupPointX;
    if (store.pickupPointY!) payload.pickupPointY = store.pickupPointY;
    if (store.zone!) payload.zoneId = store.zone!.id;
    if (store.enabledWasteTypes.length > 0) {
      payload.enabledWasteTypeIds = store.enabledWasteTypes.flatMap((wt) => wt.id);
    }
    payload.isEnabled = store.isEnabled;

    const res = await HTTP.patch(
      `stores/${storeId}`,
      payload,
    );
    return res.data;
  }

  static async deleteStore(storeId: string) {
    await HTTP.delete(`stores/${storeId}`);
  }
}

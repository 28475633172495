import Axios from 'axios';
import { API_URL } from '@/lib/http/client';
import { setCookie } from '@/lib/http/cookies';

const COOKIE_DAYS_EXPIRATION = 7;

export default class AuthApi {
  static async login(username: string, password: string) {
    try {
      const res = await Axios.post(
        `${API_URL}auth/login`,
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setCookie('token', res.data.token, COOKIE_DAYS_EXPIRATION);
      return true;
    } catch (error) {
      return false;
    }
  }
}

















































































































































import { Component, Vue } from 'vue-property-decorator';
import PickupDay, { emptyPikcupDay } from '@/models/pickupDay';
import ScheduleApi from '@/api/scheduleApi';
import Zone from '@/models/zone';
import WasteType from '@/models/wasteTypes';
import WasteTypesApi from '@/api/wasteTypesApi';
import PickupsApi from '@/api/pickupsApi';
import CreatePickupDay, { emptyCreatePikcupDay } from '@/models/createPickupDay';

@Component({
  components: {
  },
})
export default class AddPickupDay extends Vue {
  private selectedZoneId: string | null = null;

  private selectedWasteTypeId?: string | null = null;

  private dateModal = false;

  private fromTimeModal = false;

  private toTimeModal = false;

  private date = new Date().toISOString().substr(0, 10);

  private fromTime: string | null = null;

  private toTime: string | null = null;

  private errors = {};

  get zones(): Zone[] {
    return this.$store.state.zones;
  }

  get wasteTypes(): WasteType[] {
    return this.$store.state.wasteTypes;
  }

  discard() {
    this.$emit('close');
  }

  async save() {
    const pickupDay = emptyCreatePikcupDay();
    pickupDay.date = this.date;
    pickupDay.fromTime = this.fromTime!;
    pickupDay.toTime = this.toTime!;
    pickupDay.zoneId = this.selectedZoneId!;
    pickupDay.wasteTypeId = this.selectedWasteTypeId!;
    try {
      await ScheduleApi.create(pickupDay);
      this.$emit('close');
    } catch (e) {
      this.errors = e.response.data;
    }
  }
}

interface CreatePikcupDay {
  date: string;
  fromTime: string;
  toTime: string;
  zoneId: string;
  wasteTypeId: string;
}

export function emptyCreatePikcupDay(): CreatePikcupDay {
  return {
    date: '',
    fromTime: '',
    toTime: '',
    zoneId: '',
    wasteTypeId: '',
  };
}

export default CreatePikcupDay;

import { AxiosResponse } from 'axios';
// import parse from 'parse-link-header';

export interface PaginatedResponse<T> {
  results: T;
  count: number;
}

export function parsePaginatedResponse<T>(
  res: AxiosResponse,
): PaginatedResponse<T[]> {
  return {
    results: res.data,
    count: res.headers['x-total-count'],
  };
}

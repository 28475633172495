import { HTTP } from '@/lib/http/client';
import Pickup from '@/models/pickup';
import { PaginatedResponse, parsePaginatedResponse } from '@/models/paginatedResponse';

export default class PickupsApi {
  static async getAll(filters: Map<string, string>): Promise<PaginatedResponse<Pickup[]>> {
    const queryParams = Array.from(filters.keys()).map(
      (key) => `${key}=${filters.get(key)}`,
    ).join('&');
    const res = await HTTP.get(`pickups?${queryParams}`);
    return parsePaginatedResponse(res);
  }

  static async getByStoreId(storeId: string): Promise<PaginatedResponse<Pickup[]>> {
    const res = await HTTP.get(`pickups?store=${storeId}`);
    return parsePaginatedResponse(res);
  }

  static async complete(
    pickup: Pickup,
    photoWasteCollectorBeforeToken: string | null,
    photoWasteCollectorAfterToken: string | null,
  ): Promise<Pickup> {
    const res = await HTTP.post(`pickups/${pickup.id}/complete`, {
      photoWasteCollectorBeforeToken,
      photoWasteCollectorAfterToken,
    });
    return res.data;
  }
}

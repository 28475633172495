interface WasteCollector {
  id: string;
  username: string;
  email: string;
  isStaff: boolean;
}

export function emptyWasteCollector(): WasteCollector {
  return {
    id: '',
    username: '',
    email: '',
    isStaff: false,
  };
}

export default WasteCollector;












import { Component, Prop, Vue } from 'vue-property-decorator';
import WasteType, { emptyWasteType } from '@/models/wasteTypes';

@Component({
  components: {
  },
})
export default class PickupStatusChip extends Vue {
  @Prop() private status!: string;

  get color() {
    if (this.status === 'P') return 'red';
    return 'green';
  }

  get text() {
    if (this.status === 'P') return 'Pendent';
    return 'Completat';
  }
}

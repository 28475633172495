



































































































































import { Component, Vue } from 'vue-property-decorator';
import NotificationsApi from '@/api/notificationsApi';
import Zone from '@/models/zone';
import Notification, { emptyNotification } from '@/models/notification';
import NotificationTypeChip from '../components/NotificationTypeChip.vue';

@Component({
  components: {
    NotificationTypeChip,
  },
})
export default class NotificationDetails extends Vue {
  private notificationId!: string;

  private notification: Notification = emptyNotification();

  private isDeleteNotificationModalOpen = false;

  get zones(): Zone[] {
    return this.$store.state.zones;
  }

  async getNotification() {
    this.notification = await NotificationsApi.getById(this.notificationId);
  }

  async mounted() {
    this.notificationId = this.$route.params.notificationId;
    await this.getNotification();
  }

  async deleteStore() {
    await NotificationsApi.delete(this.notificationId);
    this.isDeleteNotificationModalOpen = false;
    this.$router.push({ name: 'Notifications' });
  }
}





























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Pickup, { emptyPickup } from '@/models/pickup';
import PickupsApi from '@/api/pickupsApi';
import Zone from '@/models/zone';
import WasteType from '@/models/wasteTypes';
import WasteTypeChip from '@/components/WasteTypeChip.vue';
import PickupStatusChip from '@/components/PickupStatusChip.vue';
import PickupDetails from '@/components/PickupDetails.vue';

@Component({
  components: {
    WasteTypeChip,
    PickupStatusChip,
    PickupDetails,
  },
})
export default class PickupHistory extends Vue {
  private pickups: Pickup[] = [];

  private pickupStatus = [
    { id: 'P', name: 'Pendent' },
    { id: 'C', name: 'Completat' },
  ];

  private selectedPickupStatus = null;

  private selectedWasteTypeId = null;

  private selectedZoneId = null;

  private page = 1;

  private perPage = 20;

  private pickupCount = 0;

  private fromDateModal = null;

  private fromDate = null;

  private toDateModal = null;

  private toDate = null;

  private selectedPickup: Pickup = emptyPickup();

  private bottomSheet = false;

  get zones() {
    return this.$store.state.zones;
  }

  get wasteTypes() {
    return this.$store.state.wasteTypes;
  }

  @Watch('page')
  async getPickups() {
    const params = new Map<string, string>();
    params.set('page', String(this.page));
    params.set('per_page', String(this.perPage));
    if (this.selectedPickupStatus!) params.set('status', this.selectedPickupStatus!);
    if (this.selectedZoneId!) params.set('zone', this.selectedZoneId!);
    if (this.selectedWasteTypeId!) params.set('wastetype', this.selectedWasteTypeId!);
    if (this.fromDate!) params.set('from_date', this.fromDate!);
    if (this.toDate!) params.set('to_date', this.toDate!);
    const paginatedResponse = await PickupsApi.getAll(params);
    this.pickups = paginatedResponse.results;
    this.pickupCount = Number(paginatedResponse.count);
  }

  async mounted() {
    this.getPickups();
  }

  get pagesCount() {
    let pagesCount = Math.floor(this.pickupCount / this.perPage);
    if (this.pickupCount % this.perPage !== 0) {
      pagesCount += 1;
    }
    return pagesCount;
  }

  selectPickup(pickupId: string) {
    this.bottomSheet = true;
    this.selectedPickup = this.pickups.find((p) => p.id === pickupId)!;
  }

  closeDetails() {
    this.getPickups();
    this.bottomSheet = false;
  }
}

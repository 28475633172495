




















































































































import { Component, Vue } from 'vue-property-decorator';
import { getCookie, setCookie } from '@/lib/http/cookies';
import Login from '@/components/Login.vue';

@Component({
  components: {
    Login,
  },
})
export default class App extends Vue {
  isLogedIn: boolean = getCookie('token') !== null;

  private drawer = null;

  get wasteCollector() {
    return this.$store.state.wasteCollector;
  }

  get appConfig() {
    return this.$store.state.appConfig;
  }

  mounted() {
    this.$store.dispatch('getAppConfig');
    this.$store.dispatch('getWasteCollector');
    this.$store.dispatch('getWasteTypes');
    this.$store.dispatch('getZones');
  }

  logout() {
    setCookie('token', '', 0);
    this.$router.go(0);
  }
}















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { MglMap, MglMarker, MglGeolocateControl } from 'vue-mapbox';
import Pickup, { emptyPickup } from '@/models/pickup';
import PickupsApi from '@/api/pickupsApi';
import PickupList from '@/components/PickupList.vue';
import Zone from '@/models/zone';
import WasteType from '@/models/wasteTypes';
import PickupDetails from '@/components/PickupDetails.vue';
import WasteTypesApi from '@/api/wasteTypesApi';

@Component({
  components: {
    MglMap,
    MglMarker,
    PickupList,
    PickupDetails,
    MglGeolocateControl,
  },
})
export default class Pickups extends Vue {
  private pickups: Pickup[] = [];

  private pickupStatus = [
    { id: '', name: 'Quinsevolh' },
    { id: 'P', name: 'Pendent' },
    { id: 'C', name: 'Completat' },
  ];

  private selectedPickup: Pickup = emptyPickup();

  private selectedPickupStatus = 'P';

  private mapboxPublicToken = process.env.VUE_APP_MAPBOX_PUBLIC_TOKEN;

  private mapStyle = process.env.VUE_APP_MAPBOX_MAP_STYLE;

  private coordinates = [0.7933, 42.7030];

  private zoom = 12;

  private dialog = false;

  private snackbarNewPickups = false;

  private timeout = 2000;

  private pickupsCount = 0;

  private bottomSheet = false;

  private perPage = 300;

  get zones() {
    return this.$store.state.zones;
  }

  get wasteTypes() {
    return this.$store.state.wasteTypes;
  }

  @Watch('selectedPickupStatus')
  async getPickups() {
    const params = new Map<string, string>();
    params.set('status', this.selectedPickupStatus);
    params.set('per_page', String(this.perPage));
    const paginatedResponse = await PickupsApi.getAll(params);
    this.pickups = paginatedResponse.results;
    this.pickupsCount = Number(paginatedResponse.count);
    this.snackbarNewPickups = true;
  }

  async mounted() {
    this.getPickups();
    // setInterval(() => this.getPickups(), 30000);
  }

  getCoordinates(index: number) {
    // Reversed because of the map
    return [this.pickups[index].pickupPointY, this.pickups[index].pickupPointX];
  }

  selectPickup(pickupId: string) {
    this.bottomSheet = true;
    this.selectedPickup = this.pickups.find((p) => p.id === pickupId)!;
  }

  closeDetails() {
    this.getPickups();
    this.bottomSheet = false;
  }
}

interface AppConfig {
  mapboxPublicToken: string;
  mapboxMapStyle: string;
  mainColor: string;
}

export function emptyAppConfig(): AppConfig {
  return {
    mapboxPublicToken: '',
    mapboxMapStyle: '',
    mainColor: 'green lighten-1',
  };
}

export default AppConfig;

import { HTTP } from '@/lib/http/client';
import Notification from '@/models/notification';
import { PaginatedResponse, parsePaginatedResponse } from '@/models/paginatedResponse';

export default class NotificationsApi {
  static async getAll(
    page: number,
    perPage: number,
  ): Promise<PaginatedResponse<Notification[]>> {
    const path = `notifications?page=${page}&per_page=${perPage}`;
    const res = await HTTP.get(path);
    return parsePaginatedResponse(res);
  }

  static async getById(notificationId: string): Promise<Notification> {
    const res = await HTTP.get(`notifications/${notificationId}`);
    return res.data;
  }

  static async create(notification: Notification): Promise<Notification> {
    console.log(notification);
    const payload = Object();
    payload.title = notification.title;
    payload.text = notification.text;
    payload.url = notification.url;
    payload.type = notification.type;
    payload.fromDatetime = notification.fromDatetime;
    payload.toDatetime = notification.toDatetime;
    payload.zoneIds = notification.zones.flatMap((z) => z.id);

    const res = await HTTP.post(
      'notifications',
      payload,
    );
    return res.data;
  }

  static async delete(notificationId: string) {
    await HTTP.delete(`notifications/${notificationId}`);
  }
}

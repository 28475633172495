





































































































































































































import Store, { emptyStore } from '@/models/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import StoresApi from '@/api/storesApi';
import PickupsApi from '@/api/pickupsApi';
import WasteType from '@/models/wasteTypes';
import WasteTypesApi from '@/api/wasteTypesApi';
import Pickup from '@/models/pickup';
import PickupList from '@/components/PickupList.vue';
import WasteTypeChip from '@/components/WasteTypeChip.vue';
import AppConfig from '@/models/appConfig';
import Zone from '@/models/zone';
import L, { latLng } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LWMSTileLayer,
} from 'vue2-leaflet';

@Component({
  components: {
    PickupList,
    WasteTypeChip,
    LMap,
    LTileLayer,
    LMarker,
    'l-wms-tile-layer': LWMSTileLayer,
  },
})
export default class StoreDetails extends Vue {
  private storeId!: string;

  private store: Store = emptyStore();

  private pickups: Pickup[] = [];

  private pickupCount = 0;

  private zoom = 16;

  private tileLayerURL = process.env.VUE_APP_TILE_LAYER_URL;

  private center = [0, 0];

  private map: LMap | null = null;

  private coordinates = latLng(0, 0);

  private deleteDialog = false;

  private mapOptions = {
    maxZoom: 20,
  };

  zoomUpdated(zoom: number) {
    this.zoom = zoom;
  }

  private tileLayerURL2 = 'http://mapcache.icc.cat/map/bases/service?';

  private layers = 'orto';

  private format = 'image/jpeg';

  private continuousWorld = true;

  private attribution = 'Institut Cartogràfic i Geològic de Catalunya -ICGC';

  private icon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png',
    iconSize: [32, 37],
    iconAnchor: [16, 37],
  });

  get wasteTypes(): WasteType[] {
    return this.$store.state.wasteTypes;
  }

  get zones(): Zone[] {
    return this.$store.state.zones;
  }

  async getStore() {
    this.store = await StoresApi.getById(this.storeId);
    this.center = [Number(this.store.pickupPointX), Number(this.store.pickupPointY)];
    this.coordinates = [Number(this.store.pickupPointX), Number(this.store.pickupPointY)];
    this.map!.mapObject.panTo(this.center);
  }

  async save() {
    this.store.pickupPointX = this.coordinates.lat;
    this.store.pickupPointY = this.coordinates.lng;
    this.store = await StoresApi.updateStoreById(this.storeId, this.store);
  }

  async getStorePickups() {
    const paginatedResponse = await PickupsApi.getByStoreId(this.storeId);
    this.pickups = paginatedResponse.results;
    this.pickupCount = Number(paginatedResponse.count);
  }

  mapReady(event: any) {
    this.map = this.$refs.myMap as LMap;
    this.map!.mapObject.panTo(this.center);
  }

  async mounted() {
    this.storeId = this.$route.params.storeId;
    await this.getStore();
    this.getStorePickups();
  }

  async deleteStore() {
    await StoresApi.deleteStore(this.storeId);
    this.deleteDialog = false;
    this.$router.push({ name: 'Stores' });
  }
}

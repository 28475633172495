















































































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import Pickup from '@/models/pickup';
import WasteTypeChip from '@/components/WasteTypeChip.vue';
import PickupStatusChip from '@/components/PickupStatusChip.vue';
import PickupsApi from '@/api/pickupsApi';
import FileApi from '@/api/filesApi';

@Component({
  components: {
    WasteTypeChip,
    PickupStatusChip,
  },
})
export default class PickupDetails extends Vue {
  @Prop() private pickup!: Pickup;

  private fileBefore = null;

  private fileAfter = null;

  private isUploadingBefore = false;

  private isUploadingAfter = false;

  private fileTokenBefore: string | null = null;

  private fileTokenAfter: string | null = null;

  async completePickup(pickupId: string) {
    await PickupsApi.complete(this.pickup, this.fileTokenBefore, this.fileTokenAfter);
    this.fileTokenBefore = null;
    this.isUploadingBefore = false;
    this.fileTokenBefore = null;
    this.fileTokenAfter = null;
    this.isUploadingAfter = false;
    this.fileTokenAfter = null;
    this.$emit('close');
  }

  @Watch('fileBefore')
  async uploadPhotoBefore() {
    const reader = new FileReader();
    reader.readAsDataURL(this.fileBefore!);
    reader.onload = async () => {
      this.isUploadingBefore = true;
      const fileBase64 = reader.result;
      const img = new Image();
      img.src = String(fileBase64);
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const newX = 800;
        const newY = 800;
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx!.drawImage(img, 0, 0, newX, newY);
        const data = ctx!.canvas.toDataURL();
        this.fileTokenBefore = await FileApi.uploadFile(String(data));
        this.isUploadingBefore = false;
      };
      img.onerror = (error) => {
        console.log('Error: ', error);
        this.isUploadingBefore = false;
      };
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  @Watch('fileAfter')
  async uploadPhotoAfter() {
    const reader = new FileReader();
    reader.readAsDataURL(this.fileAfter!);
    reader.onload = async () => {
      this.isUploadingAfter = true;
      const fileBase64 = reader.result;
      const img = new Image();
      img.src = String(fileBase64);
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const newX = 800;
        const newY = 800;
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx!.drawImage(img, 0, 0, newX, newY);
        const data = ctx!.canvas.toDataURL();
        this.fileTokenAfter = await FileApi.uploadFile(String(data));
        this.isUploadingAfter = false;
      };
      img.onerror = (error) => {
        console.log('Error: ', error);
        this.isUploadingAfter = false;
      };
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }
}

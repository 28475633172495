






















import { Component, Vue } from 'vue-property-decorator';
import AuthApi from '@/api/authApi';

@Component({
  components: {
  },
})
export default class Login extends Vue {
  private username = '';

  private password = '';

  private goodLogin = true;

  async login() {
    const ok = await AuthApi.login(this.username, this.password);
    if (ok) this.$router.go(0);
    else this.goodLogin = false;
  }
}

























import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import Pickup from '@/models/pickup';
import WasteTypeChip from '@/components/WasteTypeChip.vue';
import PickupStatusChip from '@/components/PickupStatusChip.vue';
import PickupsApi from '@/api/pickupsApi';
import FileApi from '@/api/filesApi';
import PickupDetails from '@/components/PickupDetails.vue';

@Component({
  components: {
    WasteTypeChip,
    PickupStatusChip,
    PickupDetails,
  },
})
export default class PickupList extends Vue {
  @Prop() private pickups: Pickup[] = [];

  clickPickup(pickupId: string) {
    this.$emit('clickPickup', pickupId);
  }
}




































































































import Store from '@/models/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import StoresApi from '@/api/storesApi';

@Component({
  components: {
  },
})
export default class Stores extends Vue {
  private stores: Store[] = [];

  private storesCount = 0;

  private pendingActivationCount = 0;

  private q = '';

  private status = 1;

  private page = 1;

  private perPage = 20;

  get statusText() {
    if (this.status === 0) return 'A';
    if (this.status === 2) return 'I';
    return '';
  }

  @Watch('q')
  @Watch('page')
  @Watch('status')
  async getStores() {
    const paginatedResponse = await StoresApi.getAll(
      this.q,
      this.statusText,
      this.page,
      this.perPage,
    );
    this.stores = paginatedResponse.results;
    this.storesCount = Number(paginatedResponse.count);
  }

  mounted() {
    this.getStores();
    this.getNotActiveCount();
  }

  get pagesCount() {
    let pagesCount = Math.floor(this.storesCount / this.perPage);
    if (this.storesCount % this.perPage !== 0) {
      pagesCount += 1;
    }
    return pagesCount;
  }

  goTo(storeId: string) {
    this.$router.push({ name: 'StoreDetails', params: { storeId } });
  }

  showNotActive() {
    this.status = 2;
    this.getStores();
  }

  async getNotActiveCount() {
    const paginatedResponse = await StoresApi.getAll('', 'I', 1, this.perPage);
    this.pendingActivationCount = Number(paginatedResponse.count);
  }
}

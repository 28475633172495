import WasteCollector, { emptyWasteCollector } from '@/models/wasteCollector';
import Vue from 'vue';
import Vuex from 'vuex';
import Pickup from '@/models/pickup';
import WasteCollectorsApi from '@/api/wasteCollectorsApi';
import WasteType from '@/models/wasteTypes';
import AppConfig, { emptyAppConfig } from '@/models/appConfig';
import WasteTypesApi from '@/api/wasteTypesApi';
import AppConfigApi from '@/api/configApi';
import Zone from '@/models/zone';
import ZonesApi from '@/api/zonesApi';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wasteTypes: Array<WasteType>(),
    zones: Array<Zone>(),
    pickups: Array<Pickup>(),
    wasteCollector: emptyWasteCollector(),
    appConfig: emptyAppConfig(),
  },
  mutations: {
    SET_ME(state, wasteCollector: WasteCollector) {
      state.wasteCollector = wasteCollector;
    },
    SET_WASTETYPES(state, wasteTypes: WasteType[]) {
      state.wasteTypes = wasteTypes;
    },
    SET_CONFIG(state, appConfig: AppConfig) {
      state.appConfig = appConfig;
    },
    SET_ZONES(state, zones: Zone[]) {
      state.zones = zones;
    },
  },
  actions: {
    async getWasteCollector(context) {
      const wasteCollector = await WasteCollectorsApi.getMe();
      context.commit('SET_ME', wasteCollector);
    },
    async getWasteTypes(context) {
      const wasteTypes = await WasteTypesApi.getAll();
      context.commit('SET_WASTETYPES', wasteTypes);
    },
    async getAppConfig(context) {
      const config = await AppConfigApi.getConfig();
      context.commit('SET_CONFIG', config);
    },
    async getZones(context) {
      const zones = await ZonesApi.getAll();
      context.commit('SET_ZONES', zones);
    },
  },
  modules: {
  },
});

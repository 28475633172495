import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Calendar from '@/views/Calendar.vue';
import Config from '@/views/Config.vue';
import PickupHistory from '@/views/PickupHistory.vue';
import Pickups from '@/views/Pickups.vue';
import StoreDetails from '@/views/StoreDetails.vue';
import Stores from '@/views/Stores.vue';
import Notifications from '@/views/Notifications.vue';
import NotificationDetails from '@/views/NotificationDetails.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Pickups,
  },
  {
    path: '/config',
    name: 'Config',
    component: Config,
  },
  {
    path: '/pickups',
    name: 'Pickups',
    component: Pickups,
  },
  {
    path: '/history',
    name: 'PickupHistory',
    component: PickupHistory,
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
  },
  {
    path: '/stores',
    name: 'Stores',
    component: Stores,
  },
  {
    path: '/stores/:storeId',
    name: 'StoreDetails',
    component: StoreDetails,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/notifications/:notificationId',
    name: 'NotificationDetails',
    component: NotificationDetails,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;

interface Zone {
  id: string;
  name: string;
}

export function emptyZone(): Zone {
  return {
    id: '',
    name: '',
  };
}

export default Zone;

import Zone from './zone';

interface Notification {
  id: string;
  zones: Zone[];
  fromDatetime: Date;
  toDatetime: Date;
  type: string;
  title: string;
  text: string;
  url: string;
  isActive: boolean;
}

export function emptyNotification(): Notification {
  return {
    id: '',
    zones: [],
    fromDatetime: new Date(),
    toDatetime: new Date(),
    type: '',
    title: '',
    text: '',
    url: '',
    isActive: false,
  };
}

export default Notification;

import axios, { AxiosInstance } from 'axios';
import humps from 'humps';
import { setCookie, getCookie } from './cookies';

export const API_URL = process.env.VUE_APP_API_URL;

export function createClient(): AxiosInstance {
  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Token ${getCookie('token')}`,
      'Content-Type': 'application/json',
    },
  });
}

export const HTTP = createClient();

HTTP.defaults.transformRequest = [(data, headers) => {
  if (data) {
    return JSON.stringify(humps.decamelizeKeys(data));
  }
  return undefined;
}];

HTTP.defaults.transformResponse = [(data, headers) => {
  if (data) {
    return humps.camelizeKeys(JSON.parse(data)); // TODO: Fix
  }
  return undefined;
}];

HTTP.interceptors.request.use(
  (config) => {
    const newConfig = config;
    newConfig.headers.Authorization = `Token ${getCookie('token')}`;
    return newConfig;
  },
  (error) => Promise.reject(error),
);

HTTP.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    setCookie('token', '', 0);
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

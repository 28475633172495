import { HTTP } from '@/lib/http/client';
import CreatePikcupDay from '@/models/createPickupDay';
import PikcupDay from '@/models/pickupDay';

export default class StoresApi {
  static async getFromDateToDate(
    fromDate: Date,
    toDate: Date,
    zoneId: string | null | undefined,
    wasteTypeId: string | null | undefined,
  ): Promise<PikcupDay[]> {
    const fromDateString = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
    const toDateString = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;
    let basePath = `schedule?from=${fromDateString}&to=${toDateString}`;
    if (zoneId!) {
      basePath = `${basePath}&zone=${zoneId}`;
    }
    if (wasteTypeId!) {
      basePath = `${basePath}&wastetype=${wasteTypeId}`;
    }
    const res = await HTTP.get(basePath);
    return res.data;
  }

  static async create(request: CreatePikcupDay): Promise<PikcupDay> {
    const createPickupDay = {
      date: request.date,
      fromTime: request.fromTime,
      toTime: request.toTime,
      zoneId: request.zoneId,
      wasteTypeId: request.wasteTypeId,
    };
    const res = await HTTP.post('schedule', createPickupDay);
    return res.data;
  }

  static async deletePickupDay(pikcupDayId: string) {
    await HTTP.delete(`schedule/${pikcupDayId}`);
  }
}








































































































import Notification from '@/models/notification';
import { Component, Vue, Watch } from 'vue-property-decorator';
import NotificationsApi from '@/api/notificationsApi';
import AddNotification from '@/components/AddNotification.vue';
import NotificationTypeChip from '../components/NotificationTypeChip.vue';

@Component({
  components: {
    AddNotification,
    NotificationTypeChip,
  },
})
export default class Notifications extends Vue {
  private notifications: Notification[] = [];

  private notificationCount = 0;

  private page = 1;

  private perPage = 50;

  private isAddNotificationModalOpen = false;

  @Watch('page')
  async getNotifications() {
    const paginatedResponse = await NotificationsApi.getAll(
      this.page,
      this.perPage,
    );
    this.notifications = paginatedResponse.results;
    this.notificationCount = Number(paginatedResponse.count);
  }

  mounted() {
    this.getNotifications();
  }

  get pagesCount() {
    let pagesCount = Math.floor(this.notificationCount / this.perPage);
    if (this.notificationCount % this.perPage !== 0) {
      pagesCount += 1;
    }
    return pagesCount;
  }

  goTo(notificationId: string) {
    this.$router.push({ name: 'NotificationDetails', params: { notificationId } });
  }

  closeAddNotificationModal() {
    this.getNotifications();
    this.isAddNotificationModalOpen = false;
  }

  getNotificationFromDatetime(notificationId: string) {
    const notification = this.notifications.find((p) => p.id === notificationId)!;
    return new Date(notification.fromDatetime).toLocaleString('en-GB');
  }

  getNotificationToDatetime(notificationId: string) {
    const notification = this.notifications.find((p) => p.id === notificationId)!;
    return new Date(notification.toDatetime).toLocaleString('en-GB');
  }

  getActiveCount() {
    return this.notifications.filter((n) => n.isActive).length;
  }
}







































































































import { Component, Vue } from 'vue-property-decorator';
import WasteCollectorsApi from '@/api/wasteCollectorsApi';

@Component({
  components: {
  },
})
export default class Config extends Vue {
  private changePasswordDialog = false;

  private currentPassword = '';

  private newPassword = '';

  private newPassword2 = '';

  private error = false;

  get wasteCollector() {
    return this.$store.state.wasteCollector;
  }

  async changePassword() {
    try {
      await WasteCollectorsApi.changePassword(
        this.currentPassword,
        this.newPassword,
      );
      this.changePasswordDialog = false;
      this.error = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.newPassword2 = '';
    } catch (e) {
      this.error = true;
    }
  }
}





























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Zone from '@/models/zone';
import { emptyNotification } from '@/models/notification';
import NotificationsApi from '@/api/notificationsApi';

@Component({
  components: {
  },
})
export default class AddNotification extends Vue {
  private isFromDateModalOpen = false;

  private isFromTimeModalOpen = false;

  private isToDateModalOpen = false;

  private isToTimeModalOpen = false;

  private fromDate = new Date().toISOString().substr(0, 10);

  private toDate = new Date().toISOString().substr(0, 10);

  private fromTime: string | null = null;

  private toTime: string | null = null;

  private title = '';

  private text = '';

  private url = '';

  private notificationType = '';

  private notificationTypes = [
    { name: 'Noticies', id: 'N' },
    { name: 'Temps', id: 'W' },
    { name: 'Avaria', id: 'I' },
    { name: 'Festiu', id: 'H' },
  ];

  // private type: string = '';

  private enabledZones: Zone[] = [];

  private errors = {};

  get zones(): Zone[] {
    return this.$store.state.zones;
  }

  discard() {
    this.$emit('close');
  }

  async save() {
    const notification = emptyNotification();

    const fromDatetime = new Date(this.fromDate);
    const fromTimeSplited = this.fromTime?.split(':', 2);
    fromDatetime.setHours(Number(fromTimeSplited![0]), Number(fromTimeSplited![1]), 0);
    notification.fromDatetime = fromDatetime;

    const toDatetime = new Date(this.toDate);
    const toTimeSplited = this.toTime?.split(':', 2);
    toDatetime.setHours(Number(toTimeSplited![0]), Number(toTimeSplited![1]), 0);
    notification.toDatetime = toDatetime;

    notification.title = this.title;
    notification.text = this.text;
    notification.url = this.url;
    notification.type = this.notificationType;
    notification.zones = this.enabledZones;
    try {
      await NotificationsApi.create(notification);
      this.$emit('close');
      this.fromDate = new Date().toISOString().substr(0, 10);
      this.toDate = new Date().toISOString().substr(0, 10);
      this.fromTime = null;
      this.toTime = null;
      this.title = '';
      this.text = '';
      this.url = '';
      this.notificationType = '';
      this.enabledZones = [];
      this.errors = {};
    } catch (e) {
      this.errors = e.response.data;
    }
  }
}

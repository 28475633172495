var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Crear ua notificacion")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"Titol","required":"","error-messages":_vm.errors.title},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Texte","required":"","error-messages":_vm.errors.text},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"URL","required":"","error-messages":_vm.errors.url},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.notificationTypes,"item-text":"name","item-value":"id","label":"Tipe","outlined":"","error-messages":_vm.errors.type},model:{value:(_vm.notificationType),callback:function ($$v) {_vm.notificationType=$$v},expression:"notificationType"}})],1),_c('v-row',[_c('v-select',{staticStyle:{"z-index":"1000"},attrs:{"items":_vm.zones,"label":"Zònes","multiple":"","chips":"","item-text":"name","return-object":true,"error-messages":_vm.errors.zoneIds,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dense":""}},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.enabledZones),callback:function ($$v) {_vm.enabledZones=$$v},expression:"enabledZones"}})],1),_c('v-row',[_vm._v(" Orari de visualitzacion ")]),_c('v-row',[_c('v-col',[_c('v-dialog',{ref:"fromDateDialog",attrs:{"return-value":_vm.fromDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fromDate=$event},"update:return-value":function($event){_vm.fromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Des de (data)","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.errors.fromDatetime},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isFromDateModalOpen),callback:function ($$v) {_vm.isFromDateModalOpen=$$v},expression:"isFromDateModalOpen"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.isFromDateModalOpen = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.fromDateDialog.save(_vm.fromDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',[_c('v-dialog',{ref:"fromTimeDialog",attrs:{"return-value":_vm.fromTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fromTime=$event},"update:return-value":function($event){_vm.fromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Des de (ora)","prepend-icon":"mdi-clock-time-four-outline","readonly":"","error-messages":_vm.errors.fromDatetime},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isFromTimeModalOpen),callback:function ($$v) {_vm.isFromTimeModalOpen=$$v},expression:"isFromTimeModalOpen"}},[(_vm.isFromTimeModalOpen)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.isFromTimeModalOpen = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.fromTimeDialog.save(_vm.fromTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{ref:"toDateDialog",attrs:{"return-value":_vm.toDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.toDate=$event},"update:return-value":function($event){_vm.toDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Enquia (data)","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.errors.toDatetime},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isToDateModalOpen),callback:function ($$v) {_vm.isToDateModalOpen=$$v},expression:"isToDateModalOpen"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.isToDateModalOpen = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.toDateDialog.save(_vm.toDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',[_c('v-dialog',{ref:"toTimeDialog",attrs:{"return-value":_vm.toTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.toTime=$event},"update:return-value":function($event){_vm.toTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Enquia (ora)","prepend-icon":"mdi-clock-time-four-outline","readonly":"","error-messages":_vm.errors.toDatetime},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isToTimeModalOpen),callback:function ($$v) {_vm.isToTimeModalOpen=$$v},expression:"isToTimeModalOpen"}},[(_vm.isToTimeModalOpen)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.isToTimeModalOpen = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.toTimeDialog.save(_vm.toTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1),(_vm.errors.error)?_c('v-row',[_vm._v(" "+_vm._s(_vm.errors.error)+" ")]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.discard()}}},[_vm._v(" Barrar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Sauvar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
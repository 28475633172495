import WasteType from './wasteTypes';
import Zone, { emptyZone } from './zone';

interface Store {
  id: string;
  email: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  zone: Zone | null;
  nif: string;
  pickupPointX: number;
  pickupPointY: number;
  enabledWasteTypes: WasteType[];
  isEnabled: boolean;
}

export function emptyStore(): Store {
  return {
    id: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zone: emptyZone(),
    nif: '',
    pickupPointX: 0,
    pickupPointY: 0,
    enabledWasteTypes: [],
    isEnabled: false,
  };
}

export default Store;
